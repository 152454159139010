export default defineAppConfig({
  strings: {
    slugs: {
      profile: 'mitaller',
      login: 'login',
      signup: 'opret',
      reset_password: 'glemt-kodeord',
      forgot_password: 'glemt-kodeord',
      new_password: 'nyt-kodeord',
    },
    menu: {
      cart: 'Kurv',
      products: 'Produkter',
    },
    newEmail: {
      success: 'Din email er blevet opdateret',
      error: 'Desværre, noget gik galt. Dobbelttjek venligst dine oplysninger og kontakt kundeservice hvis problemet fortsat opstår.',
      missmatch: 'De to email felter stemmer ikke overens. Tjek venligst og prøv igen.'
    },
    newPassword: {
      title: 'Nulstil din adgangskode',
      description: 'Indtast et nyt password for at lave en ny adgangskode',
      buttonText: 'Gem din nye adgangskode',
      confirmationTitle: 'Sådan!',
      confirmationDescription: 'Du har nu oprettet en ny adgangskode. Tryk på knappen for at fortsætte til login.',
      loading: 'Gemmer ny adgangskode...',
      success: 'Din adgangskode er nu opdateret, og du kan logge ind med din nye kode.',
      error: 'Desværre, noget gik galt. Dobbelttjek venligst dine oplysninger og kontakt kundeservice hvis problemet fortsat opstår.',
      missmatch: 'De to adgangskode felter stemmer ikke overens. Tjek venligst og prøv igen.'
    },
    selfservice: {
      myPages: 'Mine sider'
    },
    profile: {
      name: 'Navn',
      email: 'Email',
      address: 'Adresse',
      password: 'Adgangskode',
      upcomingAddressChange: 'Kommende adresseændring',
      upcomingAddressChangeDescription: 'ændrer vi din adresse til:',
      foreignAddressChange: 'Kontakt venligst kundeservice hvis du ønsker at ændre din adresse',
      deleteAddressChange: 'Kontakt venligst kundeservice hvis du ønsker at slette din kommende adresseændring.',
      temporaryAddressChange: 'Midlertidig adresseændring',
      temporaryAddressChangeDates: 'Fra den {beginDate} til den {endDate} ændrer vi midlertidigt din adresse til:',
      temporaryAddressChangeDescription: 'En midlertidig adresseændring gælder for alle dine aktive abonnementer. Bemærk at vi skal have din adresseændring senest 14 dage inden den skal træde i kraft.',
      temporaryAddressChangeDescriptionShort: 'Tilføj en midlertidig leveringsadresse til alle dine aktive abonnementer (f.eks til dit sommerhus)',
      temporaryAddressChangeButtonLabel: 'Tilføj midlertidig adresse',
      temporaryAddressChangeSuccess: 'Din midlertidige adresseændring er registreret og gælder på de valgte datoer',
      phoneNumber: 'Telefonnummer',
      phoneNumberDescription: 'Kontaktnummer som benyttes til service henvendelser',
      emailHint: 'Mail som benyttes når vi sender kvitteringer',
      customerNumberCopied: 'Kundenummer kopieret',
      addressChangeTitle: 'Foretag adresseændring',
      addressChangeDescription: 'En permanent adresseændring gælder for alle dine aktive abonnementer. Bemærk at vi skal have din adresseændring senest 14 dage inden den skal træde i kraft.',
      addressChangeSuccess: 'Din adresseændring er registreret og træder i kraft på den valgte dato',
      addressChangeError: 'Der skete en fejl. Dobbelttjek venligst dine oplysninger og kontakt kundeservice hvis problemet fortsat opstår.',
      addressChangeDate: 'Dato for adresseændring',
      addressChangeDatePlaceholder: 'Vælg fra dato',
      addressChangeButtonLabel: 'Send adresseændring',
    },
    paymentCards: {
      addCard: "Tilføj kort",
      changeCard: "Skift kort",
      success: "Dit kort er nu tilføjet og vi trækker fremover betalingen fra dette kort.",
      cancel: "Dit kort kunne ikke tilføjes. Prøv igen eller kontakt kundeservice for at få hjælp."
    },
    complaints: {
      complaint: 'Blad',
      reason: 'Årsag til reklamation',
      compensation: 'Ønske',
      complaintPlaceholder: 'Vælg blad',
      reasonPlaceholder: 'Vælg årsag',
      compensationPlaceholder: 'Vælg ønske',
      send: 'Send',
      success: 'Din reklamation er modtaget og vi vil behandle den hurtigst muligt',
      error: 'Noget gik galt med din reklamation. Prøv igen eller kontakt kundeservice hvis problemet fortsat opstår',
    },
    commitments: {
      activeCommitments: 'Aktive abonnementer',
      inactiveCommitments: 'Inaktive abonnementer',
      pay: 'Betal',
      renew: 'Forny',
      edit: 'Administrér',
      period: 'Periode',
      nextIssue: 'Næste nummer',
      status: 'Status',
      recipient: 'Modtager',
      close: 'Luk',
      pause: 'Sæt abonnement på pause',
      cancel: 'Opsig abonnement',
      pauseCancel: 'Genoptag abonnement',
      print: 'Print abonnement',
      digital: 'Digitalt abonnement',
      donation: 'Gaveabonnement',
      donationTo: '{type} til {reciever}',
      endsIn: 'Udløber',
      renewsIn: 'Fornyes',
      endedAt: 'Udløb for',
      invoices: 'Fakturaer',
      orderNumber: 'Ordrenr.',
      dueAmount: 'Beløb',
      subscriptionId: 'Abo. nr.',
    },
    commitmentCancel: {
      regretted: 'Fortrudt',
      notRead: 'Ikke læst',
      tooExpensive: 'For dyrt',
      dealOnly: 'Jeg ville kun have tilbuddet',
      differentPublication: 'Anden udgivelse',
      deliveryUnstable: 'Levering ustabil',
      loading: 'Indlæser...',
      chooseReason: 'Vælg årsag',
      cancel: 'Opsig abonnement',
      cancelling: 'Opsiger abonnement...',
      cancelled: 'Abonnement er opsigt',
      reason: 'Årsag',
      successTerminationDate: 'Dit abonnement slutter {terminationDate}',
      successNoTerminationDate: 'Dit abonnement er blevet opsagt.',
      error: 'Der skete en fejl! Vi kunne ikke opsige abonnement. Kontakt venligst kundeservice.'
    },
    commitmentSuspend: {
      fromDate: 'Fra dato',
      toDate: 'Til dato',
      suspending: 'Sætter abonnement på pause...',
      suspend: 'Sæt abonnement på pause',
      chooseFromDate: 'Vælg fra dato',
      chooseToDate: 'Vælg til dato',
      validationError: 'Ugyldig start- og/eller slutdato.',
      success: 'Dit abonnement er blevet sat på pause.',
      error: 'Der skete en fejl! Vi kunne ikke sætte abonnement på pause. Kontakt venligst kundeservice.'
    },
    commitmentsPay: {
      success: 'Vi har modtaget din betaling og dit abonnement er nu fornyet. Der kan gå op til 24 timer før du kan se ændringerne.',
      cancel: 'Din betaling er ikke gået igennem. Det er muligvis en fejl. Kontakt venligst kundeservice for at få hjælp.',
    },
    footer:{
      address:'Adresse',
      cvr:'CVR',
    }
  }
})
