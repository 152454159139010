
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/app.config.ts"
import cfg1 from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/@aller/nuxt-base/app.config.ts"
import cfg2 from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/@aller/nuxt-allerservice-sdk/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, inlineConfig)
